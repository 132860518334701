import useRequest from "@ahooksjs/use-request";
const tokenTemp = "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJzdWIiOiIyOTgxMTg3OTc0NjkwNDU5OTQiLCJqdGkiOiIyOTgxMTg3OTc0NjkwNDU5OTQiLCJpc3MiOiJzZWN1cml0eSIsImlhdCI6MTcyOTIzNjgxMiwiYXVkIjoic2VjdXJpdHktYWxsIiwiZXhwIjoxNzI5ODQxNjEyfQ.k-qlU0hW4-IM9nxuY33RDdg_Dg_EKcBggg2e51bHyhU"
// const tokenTemp = ""
export const upperPart = ({ onSuccess, onError }: any) => {
  return useRequest(
    (params: any) => ({
      url: `${process.env.HOST_API}/api/dashboard/chatter/dashboardUpperPart`,
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token') || '',
        'Content-Type': 'application/json',
        basicParams:JSON.stringify({
          "channel": "l1",
          "appCode": "sparksy",
          "appVersion": 14, 
          "token": localStorage.getItem('token') || tokenTemp
        })
      },
      body: JSON.stringify(params)
    }),
    {
      manual: true,
      debounceInterval: 500,
      throwOnError: true,
      onSuccess({ data, code, rows, msg }: any, params: any) {
        if (code === 0) {
          onSuccess(data ?? rows, params)
        } else {
          onError(msg, params)
        }
      },
      onError(err) {
        onError(err)
      },
    });
}

export const bannerList = ({ onSuccess, onError }: any) => {
  return useRequest(
    () => ({
      url: `${process.env.HOST_API}/api/dashboard/getAnnouncementList`,
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token') || '',
        'Content-Type': 'application/json',
        basicParams:JSON.stringify({
          "channel": "l1",
          "appCode": "sparksy",
          "appVersion": 14, 
          "token": localStorage.getItem('token') || tokenTemp
        })
      },
    }),
    {
      manual: true,
      debounceInterval: 500,
      throwOnError: true,
      onSuccess({ data, code, rows, msg }: any) {
        if (code === 0) {
          onSuccess(data ?? rows)
        } else {
          onError(msg)
        }
      },
      onError(err) {
        onError(err)
      },
    });
}

export const basicInfo = ({ onSuccess, onError }: any) => {
  return useRequest(
    (params: any) => ({
      url: `${process.env.HOST_API}/api/dashboard/basic/info`,
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token') || '',
        'Content-Type': 'application/json',
        basicParams:JSON.stringify({
          "channel": "l1",
          "appCode": "sparksy",
          "appVersion": 14, 
          "token": localStorage.getItem('token') || tokenTemp
        })
      },
      body: JSON.stringify(params)
    }),
    {
      manual: true,
      debounceInterval: 500,
      throwOnError: true,
      onSuccess({ data, code, msg }: any) {
        if (code === 0) {
          onSuccess(data)
        } else {
          onError(msg)
        }
      },
      onError(err) {
        onError(err)
      },
    });
}

export const modelList = ({ onSuccess, onError }: any) => {
  return useRequest(
    (params: any) => ({
      url: `${process.env.HOST_API}/api/dashboard/model/list`,
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token') || '',
        'Content-Type': 'application/json',
        basicParams:JSON.stringify({
          "channel": "l1",
          "appCode": "sparksy",
          "appVersion": 14, 
          "token": localStorage.getItem('token') || tokenTemp
        })
      },
      body: JSON.stringify(params)
    }),
    {
      manual: true,
      debounceInterval: 500,
      throwOnError: true,
      onSuccess({ data, code, msg }: any) {
        if (code === 0 ) {
          onSuccess(data)
        } else {
          onError(msg)
        }
      },
      onError(err) {
        onError(err)
      },
    });
}

export const chatList = ({ onSuccess, onError }: any) => {
  return useRequest(
    (params: any) => ({
      url: `${process.env.HOST_API}/api/dashboard/model/chat/list`,
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token') || '',
        'Content-Type': 'application/json',
        basicParams:JSON.stringify({
          "channel": "l1",
          "appCode": "sparksy",
          "appVersion": 14, 
          "token": localStorage.getItem('token') || tokenTemp
        })
      },
      body: JSON.stringify(params)
    }),
    {
      manual: true,
      debounceInterval: 500,
      throwOnError: true,
      onSuccess({ data, code, rows, msg }: any) {
        if (code === 0) {
          onSuccess(data ?? rows)
        } else {
          onError(msg)
        }
      },
      onError(err) {
        onError(err)
      },
    });
}






