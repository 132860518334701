import { useContext, lazy } from 'react';
import { Provider } from 'mobx-react';
import { Routes, Route ,useSearchParams} from "react-router-dom";
import '@/utils/request';
import './index.scss';
import Dashboard from './pages/dashboard';
const BasicData = lazy(() => import('@/pages/basicdata'))

declare global {
  interface Window {
    reload: () => void;
  }
}
function reload() {
  window.location.reload();
}
window.reload = reload;

const App = () => {

  return (
    <Provider >
      <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="basicdata" element={<BasicData />} />
      </Routes>
    </Provider>
  )

}

export default App;