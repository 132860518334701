import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, Select, Avatar, Image, Modal, message, Carousel } from "antd";
import { SpinLoading } from "antd-mobile";

import { UserOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { basicInfo, modelList, bannerList } from "./api";
import MiniBrowser from "./MiniBrowser";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import "./index.scss";
import { useEffect, useState, startTransition, useRef } from "react";
import DatePickerCustom from "../DatePickerCustom";

interface Model {
  modelCode: string;
  avatar: string;
  modelName: string;
}

// 加载插件
dayjs.extend(utc);
dayjs.extend(timezone);

const Dashboard = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const tokenParam = query.get("token");
  const goalString = localStorage.getItem("goals");
  const [basicData, setBasicData] = useState<any>({});
  const [isOpeHelp, setIsOpenHelp] = useState(false); //控制help弹窗
  const [openSel, setOpenSel] = useState<boolean>(false); //控制select下拉框
  const goals = goalString ? JSON.parse(goalString) : {}; //本周完成目标返回信息
  const [models, setModels] = useState<Model[]>([]);
  const [curData, setCurData] = useState(dayjs().tz("America/Los_Angeles"));
  const [curModel, setCurModel] = useState<Model>({
    modelCode: "",
    avatar: "",
    modelName: "All models",
  });

  const scrollableRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<any>(null);
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tokenParam && !!tokenParam) {
      localStorage.setItem("token", tokenParam);
      getBanners();
    }
  }, [tokenParam]);

  useEffect(() => {
    const element = scrollableRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const handlePopState = (event: any) => {
      // 阻止用户返回上一页
      navigate("/");
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [history]);

  const { run: getBanners } = bannerList({
    onSuccess: (res: any) => {
      setBanners(res || []);
    },
    onError: (err: any) => {
      message.warning(err || "发生错误啦，请稍后再试~");
    },
  });

  const { run: getBasedata } = basicInfo({
    onSuccess: (res: any) => {
      setBasicData(res || {});
      setLoading(false);
    },
    onError: (err: any) => {
      setLoading(false);
      message.warning(err || "发生错误啦，请稍后再试~");
    },
  });

  const { run: getModelList } = modelList({
    onSuccess: (res: any) => {
      setCurModel({ avatar: "", modelName: "All models", modelCode: "" });
      if (res && !!res) {
        const newList = res?.modelList ? [...res.modelList] : [];
        newList.unshift({
          avatar: "",
          modelName: "All models",
          modelCode: "",
        });
        setModels(newList);
      } else {
        setModels([{ avatar: "", modelName: "All models", modelCode: "" }]);
      }
      getBasedata({
        queryDate: dayjs(curData).format("YYYYMMDD"),
        modelCode: null,
      });
    },
    onError: (err: any) => {
      setModels([{ avatar: "", modelName: "All models", modelCode: "" }]);
      setCurModel({ avatar: "", modelName: "All models", modelCode: "" });
      message.warning(err || "发生错误啦，请稍后再试~");
    },
  });

  const handleClick = () => {
    startTransition(() => {
      navigate("/basicdata");
    });
  };

  const showHelp = () => {
    setIsOpenHelp(true);
  };
  const handleCancel = () => {
    setIsOpenHelp(false);
  };

  const handleChange = (value: string) => {
    const selected: any =
      models.find((model) => model.modelCode === value) || {};
    setCurModel(selected);
    setLoading(true);
    getBasedata({
      queryDate: dayjs(curData).format("YYYYMMDD"),
      modelCode: selected.modelCode,
    });
    handleScroll();
  };

  const [showMiniBrowser, setShowMiniBrowser] = useState(false);
  const [miniBrowserUrl, setMiniBrowserUrl] = useState("");

  const handleImageClick = (url: string) => {
    setMiniBrowserUrl(url);
    setShowMiniBrowser(true);
  };

  const handleCloseMiniBrowser = () => {
    setShowMiniBrowser(false);
    setMiniBrowserUrl("");
  };

  const handleBlurSelect = () => {
    setOpenSel(false);
  };

  const handleFocusSelect = () => {
    setOpenSel(true);
  };
  const handleDropdownVisibleChange = (value: any) => {
    setOpenSel(value);
  };

  const handleScroll = () => {
    setOpenSel(false);
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  const suffixIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <mask
        id="mask0_4882_993"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="8"
        height="8"
      >
        <rect width="8" height="8" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4882_993)">
        <path
          d="M1 3L4 5.5L7 3"
          stroke={openSel ? "#5552FF" : "white"}
          strokeLinecap="round"
        />
      </g>
    </svg>
  );

  const suffixicon1 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <mask
        id="mask0_4882_1010"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="8"
        height="8"
      >
        <rect
          y="8"
          width="8"
          height="8"
          transform="rotate(-90 0 8)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_4882_1010)">
        <path d="M3 7L5.5 4L3 1" stroke="#5552FF" strokeLinecap="round" />
      </g>
    </svg>
  );

  const callback = (date: any) => {
    setCurData(date);
    setLoading(true);
    getModelList({ queryDate: dayjs(date).format("YYYYMMDD") });
  };
  const lastTime = localStorage.getItem("lastTime") || null;
  return (
    <div className="wrep">
      <div className="dash">
        <div className="dash-p">Dashboard</div>
        <DatePickerCustom callback={callback} />
      </div>

      {banners ||
        ([]?.length > 0 && (
          <Carousel autoplaySpeed={5000} autoplay>
            {banners ||
              [].map((item: any, index: number) => (
                <div key={"banner_" + index}>
                  <a
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      handleImageClick(item.html);
                    }}
                  >
                    <Image
                      // loading="lazy"
                      src={item.banner}
                      preview={false}
                      height={100}
                      style={{ display: "block" }} //objectFit: "cover"
                    />
                  </a>
                </div>
              ))}
          </Carousel>
        ))}
      {showMiniBrowser && (
        <MiniBrowser url={miniBrowserUrl} onClose={handleCloseMiniBrowser} />
      )}

      <div className="last">
        <div className="last-l">Last updated:</div>
        {lastTime ? (
          <div className="last-r">{lastTime}</div>
        ) : (
          <SpinLoading style={{ "--size": "20px" }} />
        )}
      </div>

      {/* 评级说明 */}
      <div className="level">
        <div className="help" onClick={showHelp}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <path
              d="M7.5 0C3.36161 0 0 3.36161 0 7.5C0 11.6384 3.36161 15 7.5 15C11.6384 15 15 11.6384 15 7.5C15 3.36161 11.6384 0 7.5 0ZM8.03571 11.7857H6.96429V10.7143H8.03571V11.7857ZM8.22321 8.38393L8.12946 8.46429C8.07589 8.50446 8.03571 8.59821 8.03571 8.67857V9.65625H6.96429V8.67857C6.96429 8.26339 7.15179 7.875 7.45982 7.62054L7.55357 7.54018C8.49107 6.79018 8.94643 6.40179 8.94643 5.73214C8.94643 4.92857 8.30357 4.28571 7.5 4.28571C6.66964 4.28571 6.05357 4.90179 6.05357 5.73214H4.98214C4.98214 4.32589 6.09375 3.21429 7.5 3.21429C8.89286 3.21429 10.0179 4.33929 10.0179 5.73214C10.0179 6.95089 9.1875 7.62054 8.22321 8.38393Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="explain">
          <div className="explain-l">
            Weekly data is tracked from Monday to Sunday, Los Angeles time. Your
            rating for the week will be updated after Sunday. Please keep an eye
            on your deep chats (customers replied over 30 messages and your chat
            achieved Lv4) and PPV goals. Meeting these targets will lead to
            higher pay and a higher rating.
          </div>
          <div className="explain-r">
            <Image
              preview={false}
              src={require(`../../assets/img/${
                (localStorage.getItem("rating") || "B").toLowerCase() + ".png"
              }`)}
              onError={() => {
                return null;
              }}
            />
          </div>
        </div>
      </div>

      <div
        ref={scrollableRef}
        style={{
          overflowY: "auto",
          flexGrow: 1,
          height:
            banners?.length == 0
              ? "calc(100vh - 283px)"
              : "calc(100vh - 393px)",
        }}
      >
        {/* 本周目标 */}
        <div className="goal">
          <div className="title">This week&apos;s goal</div>
          <div className="content">
            <div className="text">
              <div className="text-p">ppv</div>
              <div className="text-p">
                {goals?.ppvCount || 0}/{goals?.ppvNextGoalCount || 0}
              </div>
            </div>
            <div className="process">
              <p
                className="process-s"
                style={{ width: goals?.ppvFinishPercent || 0 }} //goals?.ppvFinishPercent || 0
              ></p>
              <p className="process-p">{goals?.ppvFinishPercent}</p>
            </div>
            <div className="text" style={{ marginTop: "6px" }}>
              <div className="text-p text-p0" style={{ fontSize: "12px" }}>
                0
              </div>
              <div className="text-p text-p0" style={{ fontSize: "12px" }}>
                100%
              </div>
            </div>

            <div className="text" style={{ marginTop: "30px" }}>
              <div className="text-p">New DC</div>
              <div className="text-p">
                {goals?.deepChatCount || 0}/{goals?.deepChatNextGoalCount || 0}
              </div>
            </div>
            <div className="process">
              <p
                className="process-s2"
                style={{ width: goals?.deepChatFinishPercent || 0 }}
              ></p>
              <p className="process-p process-p2">
                {goals?.deepChatFinishPercent}
              </p>
            </div>
            <div className="text" style={{ marginTop: "6px" }}>
              <div className="text-p text-p0" style={{ fontSize: "12px" }}>
                0
              </div>
              <div className="text-p text-p0" style={{ fontSize: "12px" }}>
                100%
              </div>
            </div>
          </div>
        </div>

        {/* 基础数据 */}
        <div className="base">
          <div className="base-title">
            <Button
              className="btn"
              style={{ outline: "none" }}
              onClick={handleClick}
            >
              Basic Data (Dally) {suffixicon1}
            </Button>
            <div
              style={{
                display: "flex",
                position: "relative",
                alignItems: "center",
              }}
            >
              {curModel?.avatar ? (
                <Avatar
                  src={curModel?.avatar}
                  size="small"
                  shape="square"
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    left: "8px",
                    zIndex: 2,
                  }}
                />
              ) : (
                <UserOutlined
                  style={{
                    position: "absolute",
                    left: "10px",
                    color: openSel ? "#5552FF" : "#FFF",
                    zIndex: 2,
                  }}
                />
              )}
              <Select
                ref={selectRef}
                className={`${openSel ? "active" : ""}`}
                value={curModel.modelCode}
                style={{ width: "124px", fontSize: 12 }}
                onChange={handleChange}
                open={openSel}
                onDropdownVisibleChange={handleDropdownVisibleChange}
                onBlur={handleBlurSelect}
                onFocus={handleFocusSelect}
                optionLabelProp="modelName"
                placeholder="All models"
                options={models?.map((model: any, index) => ({
                  value: model?.modelCode,
                  label: (
                    <div>
                      <Avatar
                        src={
                          model?.avatar || (
                            <UserOutlined style={{ color: "#5552FF" }} />
                          )
                        }
                        shape="square"
                        size="small"
                        style={{ marginRight: 8 }}
                      />
                      <span className="model_name" style={{ fontSize: "12px" }}>
                        {model?.modelName || ""}
                      </span>
                    </div>
                  ),
                  modelName: model?.modelName || "",
                }))}
                suffixIcon={suffixIcon}
              />
            </div>
          </div>
          <div className={`base-content ${loading ? "mask" : ""}`}>
            {loading && (
              <div className="mask-load">
                <SpinLoading style={{ "--size": "48px" }} />
              </div>
            )}

            <p className="pp">
              PP quantity:{" "}
              {basicData?.dcPPNum || 0 + basicData?.beforeDcPPNum || 0}
            </p>
            <div className="list" style={{ marginBottom: 25 }}>
              <div>
                <div className="item-t">PP (DC):</div>
                <div className="item-v">{basicData?.dcPPNum || 0}</div>
              </div>
              <div>
                <div className="item-t">PP (before DC):</div>
                <div className="item-v">{basicData?.beforeDcPPNum || 0}</div>
              </div>
            </div>

            <p className="pp">
              PV quantity:{" "}
              {basicData?.dcPVNum || 0 + basicData?.beforeDcPVNum || 0}
            </p>
            <div className="list" style={{ marginBottom: 15 }}>
              <div>
                <div className="item-t">PV (DC):</div>
                <div className="item-v">{basicData?.dcPVNum || 0}</div>
              </div>
              <div>
                <div className="item-t">PV (before DC):</div>
                <div className="item-v">{basicData?.beforeDcPVNum || 0}</div>
              </div>
              <div>
                <div className="item-t">New DC:</div>
                <div className="item-v">{basicData?.dcTodayNum || 0}</div>
              </div>
            </div>
            <div
              style={{ borderTop: "1px dashed #E6E6E6", marginBottom: "15px" }}
            ></div>

            <div className="list">
              <div>
                <div className="item-t">New Matches:</div>
                <div className="item-v">{basicData?.newChatsNum || 0}</div>
              </div>
              <div>
                <div className="item-t">Active Old Customers:</div>
                <div className="item-v">{basicData?.oldUserMsgNum || 0}</div>
              </div>

              <div>
                <div className="item-t">Active Chats of Today:</div>
                <div className="item-v">{basicData?.chatsTodayNum || 0}</div>
              </div>
              <div>
                <div className="item-t">Active Chats in 7 Days:</div>
                <div className="item-v">
                  {basicData?.chatsLatestWeekNum || 0}
                </div>
              </div>

              <div>
                <div className="item-t">New Match Acceptance Rate:</div>
                <div className="item-v">
                  <div>{basicData?.newAcceptanceRate || 0}</div>
                  <div className="item-per">
                    {basicData?.newAcceptanceTarget || 0 + "%"}{" "}
                  </div>
                </div>
              </div>
              <div>
                <div className="item-t">Old Match Acceptance Rate:</div>
                <div className="item-v">
                  <div>{basicData?.oldAcceptanceRate || 0}</div>
                  <div className="item-per">
                    {basicData?.oldAcceptanceTarget || 0 + "%"}{" "}
                  </div>
                </div>
              </div>

              <div>
                <div className="item-t">Online Rate:</div>
                <div className="item-v">
                  <div>{basicData?.onlineRate || 0}</div>
                  <div className="item-per">
                    {basicData?.onlineTimesTarget || 0 + "%"}{" "}
                  </div>
                </div>
              </div>
              <div>
                <div className="item-t">Timely Response Rate:</div>
                <div className="item-v">
                  <div>{basicData?.replyOnTimeRate || 0}</div>
                  <div className="item-per">
                    {basicData?.repliedOnTimeTarget || 0 + "%"}{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="footer">
              The data maybe slightly delayed ，try refreshing it several times
            </div>
          </div>
        </div>
      </div>

      <Modal
        title=""
        open={isOpeHelp}
        onCancel={handleCancel}
        width={292}
        maskClosable={false}
        footer={null}
      >
        <h3>Rating Standards:</h3>
        <p>
          <b>SS Level:</b> Attendance of 6 days or more per week, with weekly
          PPV sales of 720 or more.
        </p>
        <br />
        <p>
          <b>S Level:</b> Attendance of 6 days or more per week, with weekly PPV
          sales of 360 or more but less than 720.
        </p>
        <br />
        <p>
          <b>A Level:</b> Attendance of 6 days or more per week, with weekly PPV
          sales of 180 or more but less than 360.
        </p>
        <br />
        <p>
          <b>B Level:</b> PPV sales are less than 180
        </p>
        <br />
      </Modal>
    </div>
  );
};
export default Dashboard;
