// MiniBrowser.tsx
import { Button } from 'antd';
import React from 'react';

interface MiniBrowserProps {
  url: string;
  onClose: () => void;
}

const MiniBrowser: React.FC<MiniBrowserProps> = ({ url, onClose }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '0px',
        width: '100vw',
        height:"100vh",
        // height: 'calc(100vh - 40px)',
        border: '1px solid #ccc',
        background: 'white',
        zIndex: 1000,
      }}
    >
      <button
        onClick={onClose}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'red',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          zIndex: 1001, 
        }}
      >
        X
      </button>
      <iframe
        src={url}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          zIndex:999,
          pointerEvents:"none"
        }}
      />
    </div>
  );
};

export default MiniBrowser;
